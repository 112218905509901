import React from 'react';
import { motion } from 'framer-motion';
import { Home, Building2, Car, Warehouse, Archive } from 'lucide-react';
import residential from '../Images/homes.jpg'
import commercial from '../Images/commercial.jpg'
import autobody from '../Images/autobody.jpg'
import garage from '../Images/storage.jpg'
import underground from '../Images/underground.jpg'

const IndustryCard = ({ icon: Icon, title, imageUrl }) => (
  <motion.div 
    className="relative overflow-hidden group h-[40vh] md:h-[60vh] lg:h-[80vh]"
    initial={{ opacity: 0, scale: 0.9 }}
    whileInView={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 group-hover:bg-opacity-70"></div>
    <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-white text-center">
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <Icon className="w-16 h-16 mb-4" />
        <h3 className="text-3xl font-bold mb-2">{title}</h3>
      </motion.div>
    </div>
  </motion.div>
);

const Industries = () => {
  const industries = [
    { icon: Home, title: "Residential", imageUrl: residential },
    { icon: Building2, title: "Commercial", imageUrl: commercial },
    { icon: Car, title: "Auto Body", imageUrl: autobody },
    { icon: Warehouse, title: "Underground", imageUrl: underground  },
    { icon: Archive, title: "Storage", imageUrl: garage},
  ];

  return (
    <section id="industries" className="bg-gray-100">
      <motion.h2 
        className="text-4xl md:text-5xl font-bold text-center py-16 text-gray-800"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        Industries We Service
      </motion.h2>
      <div className="flex flex-col md:flex-row">
        {industries.map((industry, index) => (
          <IndustryCard key={index} {...industry} />
        ))}
      </div>
      <motion.div 
        className="py-16 text-center"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        viewport={{ once: true }}
      >
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          No matter your industry, we have the expertise to handle all your garage door needs.
        </p>
      </motion.div>
    </section>
  );
};

export default Industries;