import React, { useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Wrench, Home, Clock } from 'lucide-react';
import repair from '../Images/repair.jpg';
import installation from '../Images/installation.jpg';
import emergency from '../Images/emergency.jpg';

const ServiceCard = ({ icon: Icon, title, description, imageUrl }) => {
  return (
    <motion.div 
      className="relative overflow-hidden group h-[40vh] md:h-[60vh] lg:h-[80vh]"
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 group-hover:bg-opacity-70"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-white text-center">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Icon className="w-16 h-16 mb-4" />
          <h3 className="text-3xl font-bold mb-2">{title}</h3>
          <p className="text-lg max-w-md mx-auto">
            {description}
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
};

const servicesData = [
  {
    icon: Wrench,
    title: "Repair Services",
    description: "Fast and reliable garage door repair for all makes and models. Our expert technicians can handle any issue, big or small.",
    imageUrl: repair
  },
  {
    icon: Home,
    title: "Installation",
    description: "Expert installation of new garage doors and openers. We ensure perfect fit and smooth operation for your new garage door system.",
    imageUrl: installation
  },
  {
    icon: Clock,
    title: "24/7 Service",
    description: "Emergency garage door services available around the clock. We're always ready to assist you, day or night.",
    imageUrl: emergency
  }
];

const Services = () => {
  const services = useMemo(() => servicesData, []);

  useEffect(() => {
    services.forEach(service => {
      const img = new Image();
      img.src = service.imageUrl;
    });
  }, [services]);

  return (
    <section id="services" className="bg-gray-100">
      <motion.h2 
        className="text-4xl md:text-5xl font-bold text-center py-16 text-gray-800"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        Servicing South Florida for 5+ Years
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
      <motion.div 
        className="py-16 text-center"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        viewport={{ once: true }}
      >
<motion.button 
  className="bg-blue-600 text-white text-xl px-10 py-4 rounded-full hover:bg-blue-700 transition duration-300 shadow-lg"
  whileHover={{ scale: 1.05, boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)" }}
  whileTap={{ scale: 0.95 }}
  onClick={() => window.location.href = 'tel:3053004211'}
>
  Schedule a Service
</motion.button>

      </motion.div>
    </section>
  );
};

export default Services;