import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Menu, X, Home, Wrench, Info, Phone } from 'lucide-react';
import Cooper from '../Images/Cooper.png';

const Header = ({ scrollToSection }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const progress = Math.min(scrollY / windowHeight, 1);
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    controls.start({
      backgroundColor: `rgba(255, 255, 255, ${scrollProgress})`,
      boxShadow: `0 4px 6px -1px rgba(0, 0, 0, ${scrollProgress * 0.1}), 0 2px 4px -1px rgba(0, 0, 0, ${scrollProgress * 0.06})`,
    });
  }, [scrollProgress, controls]);

  const handleNavClick = (sectionId) => {
    scrollToSection(sectionId);
    setMenuOpen(false);
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleEstimateClick = () => {
    window.location.href = 'tel:3053004211';
  };

  const textColor = scrollProgress > 0.5 ? 'text-gray-600' : 'text-white';
  const hoverColor = 'hover:text-blue-600';

  return (
    <motion.header
      className="fixed top-0 left-0 right-0 z-40"
      initial={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}
      animate={controls}
      transition={{ duration: 0.3 }}
    >
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <motion.div 
          className="w-16 h-16 md:w-20 md:h-20 flex items-center justify-center cursor-pointer"
          onClick={handleLogoClick}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <img src={Cooper} alt="Cooper Garage Door Repair Logo" className="w-full h-full object-contain" />
        </motion.div>
        <nav className="hidden md:flex items-center space-x-6">
          {['home', 'services', 'about', 'contact'].map((item) => (
            <motion.button
              key={item}
              onClick={() => handleNavClick(item)}
              className={`${textColor} ${hoverColor} flex items-center transition duration-300`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {item === 'home' && <Home className="w-4 h-4 mr-2" />}
              {item === 'services' && <Wrench className="w-4 h-4 mr-2" />}
              {item === 'about' && <Info className="w-4 h-4 mr-2" />}
              {item === 'contact' && <Phone className="w-4 h-4 mr-2" />}
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </motion.button>
          ))}
          <motion.button
            onClick={handleEstimateClick}
            className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Phone className="w-4 h-4 mr-2 inline" />
            Schedule Free Estimate
          </motion.button>
        </nav>
        <motion.button
          onClick={() => setMenuOpen(!menuOpen)}
          className="md:hidden"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          {menuOpen ? <X size={24} color={scrollProgress > 0.5 ? "#333" : "#fff"} /> : <Menu size={24} color={scrollProgress > 0.5 ? "#333" : "#fff"} />}
        </motion.button>
      </div>
      {menuOpen && (
        <motion.div
          className="md:hidden bg-white py-4 px-4 shadow-md"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          {['home', 'services', 'about', 'contact'].map((item) => (
            <motion.button
              key={item}
              onClick={() => handleNavClick(item)}
              className="block w-full text-left py-2 text-gray-600 hover:text-blue-600 transition duration-300"
              whileHover={{ x: 5 }}
            >
              {item === 'home' && <Home className="w-4 h-4 mr-2 inline" />}
              {item === 'services' && <Wrench className="w-4 h-4 mr-2 inline" />}
              {item === 'about' && <Info className="w-4 h-4 mr-2 inline" />}
              {item === 'contact' && <Phone className="w-4 h-4 mr-2 inline" />}
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </motion.button>
          ))}
          <motion.button
            onClick={handleEstimateClick}
            className="w-full mt-4 bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Phone className="w-4 h-4 mr-2 inline" />
            Schedule Free Estimate
          </motion.button>
        </motion.div>
      )}
    </motion.header>
  );
};

export default Header;