import React from 'react';
import { Phone, Mail, MapPin } from 'lucide-react';
import Cooperlogo from '../Images/Cooperwhite.png'

const Footer = () => {
  return (
    <footer className="bg-blue-600 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-8 md:mb-0">
            <img src= {Cooperlogo} alt="Cooper Garage Door Repair" className="h-16 mb-4" />
            <p className="text-sm opacity-80 max-w-xs">
              Providing top-quality garage door services to our community for over 20 years.
            </p>
          </div>
          <div className="flex flex-col items-center md:items-end">
            <h4 className="text-xl font-bold mb-4">Contact Us</h4>
            <div className="flex flex-col items-center md:items-end space-y-2">
              <a href="tel:5551234567" className="flex items-center hover:text-blue-200 transition-colors">
                <Phone className="w-5 h-5 mr-2" />
                (305) 300-4211
              </a>
              <a href="mailto:info@coopergaragedoor.com" className="flex items-center hover:text-blue-200 transition-colors">
                <Mail className="w-5 h-5 mr-2" />
                info@coopergarageservices.com
              </a>
              <p className="flex items-center">
                <MapPin className="w-5 h-5 mr-2" />
                5800 Lakeshore Avenue, Fort Lauderdale, FL 33321
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-blue-400 text-center">
          <p>&copy; {new Date().getFullYear()} Cooper Garage Door Repair. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;