import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Phone, MapPin } from 'lucide-react';

const Contact = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const handleCallClick = () => {
    window.location.href = 'tel:3053004211';
  };

  return (
    <section id="contact" className="py-20 bg-blue-600 text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16"
          variants={itemVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          Let's Connect
        </motion.h2>
        <motion.div
          className="grid md:grid-cols-2 gap-12 items-center"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <motion.div variants={itemVariants} className="space-y-8">
            <h3 className="text-3xl font-semibold mb-6">Get in Touch</h3>
            <p className="text-xl mb-8">
              We're here to help with all your garage door needs. Reach out to us for expert service and support.
            </p>
            <ul className="space-y-6">
              <li className="flex items-center">
                <Phone className="w-8 h-8 mr-4 text-blue-300" />
                <span className="text-xl">(305) 300-4211</span>
              </li>
              <li className="flex items-center">
                <Mail className="w-8 h-8 mr-4 text-blue-300" />
                <span className="text-xl">contact@coopergarageservices.com</span>
              </li>
              <li className="flex items-center">
                <MapPin className="w-8 h-8 mr-4 text-blue-300" />
                <span className="text-xl">5800 Lakeshore Avenue, Fort Lauderdale, FL 33312</span>
              </li>
            </ul>
            <motion.button
              className="mt-8 bg-white text-blue-600 text-xl px-10 py-4 rounded-full hover:bg-blue-100 transition duration-300 shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleCallClick}
            >
              Schedule Free Estimate
            </motion.button>
          </motion.div>
          <motion.div variants={itemVariants} className="h-96 md:h-full relative">
            <div className="absolute inset-0 bg-white bg-opacity-20 backdrop-blur-sm rounded-xl overflow-hidden shadow-2xl">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7151246.1037771!2d-86.50213022944553!3d27.836182336216963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c1766591562abf%3A0xf72e13d35bc74ed0!2sFlorida!5e0!3m2!1sen!2sus!4v1682803681654!5m2!1sen!2sus"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Florida Map"
              ></iframe>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;