import React from 'react';
import { motion } from 'framer-motion';
import { Clock, Hammer, Shield, Leaf } from 'lucide-react';

const FeatureIcon = ({ Icon, text }) => (
  <div className="flex flex-col items-center">
    <div className="bg-blue-100 p-4 rounded-full mb-3">
      <Icon className="w-8 h-8 text-blue-600" />
    </div>
    <p className="text-sm text-gray-600 text-center">{text}</p>
  </div>
);

const About = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section id="about" className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <motion.h2 variants={itemVariants} className="text-4xl md:text-5xl font-bold text-center mb-16 text-gray-800">
            Elevating Garage Door Services
          </motion.h2>

          <motion.div variants={itemVariants} className="mb-20">
            <div className="relative">
              <div className="absolute inset-0 bg-blue-600 transform -skew-y-6"></div>
              <div className="relative bg-white shadow-xl p-8 md:p-12 rounded-lg">
                <p className="text-lg text-gray-700 leading-relaxed mb-6">
                  At Cooper Garage Door Repair, we've redefined excellence in garage door services. Our journey began over 5 years ago with a simple mission: to provide unparalleled quality and customer satisfaction in every interaction.
                </p>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Today, we stand as industry leaders, blending time-tested expertise with cutting-edge technology. Our team of master technicians doesn't just fix doors; we optimize your home's functionality and security.
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div variants={itemVariants} className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-20">
            <FeatureIcon Icon={Clock} text="24/7 Emergency Services" />
            <FeatureIcon Icon={Hammer} text="Customized Solutions" />
            <FeatureIcon Icon={Shield} text="Transparent Pricing" />
            <FeatureIcon Icon={Leaf} text="Eco-Friendly Options" />
          </motion.div>

          <motion.div variants={itemVariants} className="text-center">
            <h3 className="text-2xl font-semibold mb-6 text-gray-800">Ready to upgrade your garage experience?</h3>
            <motion.button
  className="bg-blue-600 text-white px-10 py-4 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300 shadow-lg"
  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
  onClick={() => window.location.href = 'tel:3053004211'}
>
  Schedule a Consultation
</motion.button>

          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default About;