import React, { useState } from 'react';
import { Search, MapPin } from 'lucide-react';
import BackgroundImage from '../Images/background.jpg'; 

const zipCodeData = {
    '32003': 'Ridgewood',
    '32004': 'Ponte Vedra Beach',
    '32006': 'Fleming Island',
    '32007': 'Bostwick',
    '32008': 'Branford',
    '32009': 'Bryceville',
    '32011': 'Callahan',
    '32013': 'Day',
    '32024': 'Lake City',
    '32025': 'Lake City',
    '32030': 'Ridgewood',
    '32033': 'Elkton',
    '32034': 'Fernandina Beach',
    '32035': 'Downtown Fernandina Beach',
    '32038': 'Fort White',
    '32040': 'Glen Saint Mary',
    '32041': 'Yulee',
    '32042': 'Graham',
    '32043': 'Green Cove Springs',
    '32044': 'Hampton',
    '32046': 'Hilliard',
    '32050': 'Middleburg',
    '32052': 'Jasper',
    '32053': 'Jennings',
    '32054': 'Lake Butler',
    '32055': 'Lake City',
    '32056': 'Lake City',
    '32058': 'Lawtey',
    '32059': 'Lee',
    '32060': 'Live Oak',
    '32061': 'Lake Butler',
    '32062': 'Mc Alpin',
    '32063': 'Macclenny',
    '32064': 'Dowling Park',
    '32065': 'Ridgewood',
    '32066': 'Mayo',
    '32067': 'Orange Park',
    '32068': 'Middleburg',
    '32071': 'O Brien',
    '32073': 'Orange Park',
    '32079': 'Penney Farms',
    '32080': 'Anastasia Island',
    '32081': 'Ponte Vedra Beach',
    '32082': 'Ponte Vedra Beach',
    '32083': 'Raiford',
    '32084': 'Saint Augustine',
    '32085': 'Saint Augustine',
    '32086': 'Saint Augustine Annex',
    '32087': 'Glen Saint Mary',
    '32091': 'Starke',
    '32092': 'World Golf Village Annex',
    '32094': 'Wellborn',
    '32095': 'World Golf Village Annex',
    '32096': 'White Springs',
    '32097': 'Yulee',
    '32102': 'Astor',
    '32105': 'Barberville',
    '32110': 'Bunnell',
    '32111': 'Candler',
    '32112': 'Crescent City',
    '32113': 'Citra',
    '32114': 'Daytona Beach',
    '32115': 'Downtown Daytona Beach',
    '32116': 'Daytona Beach Shores',
    '32117': 'Daytona Beach',
    '32118': 'Downtown Daytona Beach',
    '32119': 'Daytona Beach',
    '32120': 'Daytona Beach',
    '32121': 'South Daytona',
    '32122': 'Holly Hill',
    '32123': 'Allandale',
    '32124': 'Daytona Beach',
    '32125': 'Holly Hill',
    '32126': 'Downtown Daytona Beach',
    '32127': 'Port Orange',
    '32128': 'Port Orange',
    '32129': 'Port Orange',
    '32130': 'De Leon Springs',
    '32131': 'East Palatka',
    '32132': 'Edgewater',
    '32133': 'Eastlake Weir',
    '32134': 'Fort Mc Coy',
    '32135': 'Palm Coast',
    '32136': 'Flagler Beach',
    '32137': 'Palm Coast',
    '32138': 'Grandin',
    '32139': 'Crescent City',
    '32140': 'Florahome',
    '32141': 'Edgewater',
    '32142': 'Palm Coast',
    '32143': 'Palm Coast',
    '32145': 'Hastings',
    '32147': 'Hollister',
    '32148': 'Interlachen',
    '32157': 'Lake Como',
    '32158': 'Lady Lake',
    '32159': 'Lady Lake',
    '32160': 'Lake Geneva',
    '32162': 'Lady Lake',
    '32163': 'Lady Lake',
    '32164': 'Palm Coast',
    '32168': 'New Smyrna Beach',
    '32169': 'New Smyrna Beach',
    '32170': 'CPU Coronado',
    '32173': 'Ormond Beach',
    '32174': 'Ormond Beach',
    '32175': 'Ormond Beach Beachside',
    '32176': 'Ormond Beach Beachside',
    '32177': 'Palatka',
    '32178': 'Palatka',
    '32179': 'Ocklawaha',
    '32180': 'Pierson',
    '32181': 'Pomona Park',
    '32182': 'Orange Springs',
    '32183': 'Ocklawaha',
    '32185': 'Putnam Hall',
    '32187': 'San Mateo',
    '32189': 'Satsuma',
    '32190': 'Pierson',
    '32192': 'Sparr',
    '32193': 'Welaka',
    '32195': 'Weirsdale',
    '32201': 'Jacksonville MOWU MY PO',
    '32202': 'Gateway Carrier Annex',
    '32203': 'Jacksonville MOWU MY PO',
    '32204': 'Murray Hill',
    '32205': 'CPU Herschel Gates Contract',
    '32206': 'Springfield',
    '32207': 'South Jacksonville',
    '32208': 'Lake Forest',
    '32209': 'Carver',
    '32210': 'Lake Shore',
    '32211': 'Arlington',
    '32212': 'Jacksonville NAS',
    '32216': 'CPU SO University Halliday',
    '32217': 'Mandarin',
    '32218': 'North Jacksonville',
    '32219': 'Lake Forest',
    '32220': 'Murray Hill',
    '32221': 'Murray Hill',
    '32222': 'Westland',
    '32223': 'Mandarin',
    '32224': 'Atlantic Beach',
    '32225': 'Monument',
    '32226': 'North Jacksonville',
    '32227': 'Atlantic Beach',
    '32228': 'Mayport Naval Station',
    '32229': 'North Jacksonville',
    '32231': 'Jacksonville MOWU MY PO',
    '32232': 'Jacksonville MOWU MY PO',
    '32233': 'Atlantic Beach',
    '32234': 'Baldwin',
    '32235': 'Monument',
    '32236': 'Murray Hill',
    '32238': 'Lake Shore',
    '32239': 'Arlington',
    '32240': 'Jacksonville Beach',
    '32241': 'Mandarin',
    '32244': 'Westland',
    '32245': 'Pottsburg',
    '32246': 'Pottsburg',
    '32247': 'South Jacksonville',
    '32250': 'Jacksonville Beach Annex',
    '32254': 'Murray Hill',
    '32255': 'Southpoint',
    '32256': 'Baymeadows DDC',
    '32257': 'Mandarin',
    '32258': 'Baymeadows DDC',
    '32259': 'Baymeadows DDC',
    '32260': 'NW Saint Johns',
    '32266': 'Atlantic Beach',
    '32277': 'Arlington',
    '33301': 'Fort Lauderdale FL S&DC',
    '33302': 'Alridge',
    '33303': 'Colee',
    '33304': 'Fort Lauderdale FL S&DC',
    '33305': 'Fort Lauderdale FL S&DC',
    '33306': 'Fort Lauderdale FL S&DC',
    '33307': 'Oakland Park',
    '33308': 'Fort Lauderdale FL S&DC',
    '33309': 'Fort Lauderdale FL S&DC',
    '33310': 'Fort Lauderdale FL S&DC',
    '33311': 'Fort Lauderdale FL S&DC',
    '33312': 'Melrose Vista',
    '33313': 'Fort Lauderdale FL S&DC',
    '33314': 'Davie',
    '33315': 'Southside',
    '33316': 'Causeway',
    '33317': 'Plantation',
    '33318': 'Plantation',
    '33319': 'Fort Lauderdale FL S&DC',
    '33320': 'Tamarac',
    '33321': 'Tamarac',
    '33322': 'Sunrise',
    '33323': 'Sunrise',
    '33324': 'Plantation',
    '33325': 'Weston',
    '33326': 'Weston',
    '33327': 'Weston',
    '33328': 'Davie',
    '33329': 'Davie',
    '33330': 'Everglades BR',
    '33331': 'Everglades BR',
    '33332': 'Everglades BR',
    '33334': 'Fort Lauderdale FL S&DC',
    '33335': 'Southside',
    '33338': 'Gateway',
    '33339': 'Coral Ridge',
    '33340': 'Fort Lauderdale FL S&DC',
    '33345': 'Sunrise',
    '33346': 'Causeway',
    '33348': 'Galt Ocean',
    '33351': 'Tamarac',
    '33355': 'Westside',
    '33359': 'Sabal Palm',
    '33388': 'Plantation',
    '33394': 'Fort Lauderdale FL S&DC',
    '33401': 'City Place',
    '33402': 'Downtown West Palm Beach',
    '33403': 'Lake Park',
    '33404': 'Riviera Beach',
    '33405': 'Palms Central',
    '33406': 'Palms Central',
    '33407': 'Northwood',
    '33408': 'North Palm Beach',
    '33409': 'Haverhill BR',
    '33410': 'Palm Beach Gardens',
    '33411': 'Palms West',
    '33412': 'Riviera Beach',
    '33413': 'Palms Central',
    '33414': 'Wellington Carrier Annex',
    '33415': 'Palms Central',
    '33416': 'West Palm Beach Window Unit',
    '33417': 'Haverhill BR',
    '33418': 'Palm Beach Gardens',
    '33419': 'Riviera Beach',
    '33420': 'Palm Beach Gardens',
    '33421': 'Palms West',
    '33422': 'Haverhill BR',
    '33424': 'Boynton Beach',
    '33425': 'Downtown Boynton Beach',
    '33426': 'Boynton Beach',
    '33427': 'Palmetto Park',
    '33428': 'West Boca Carrier Annex',
    '33429': 'Downtown Boca Raton',
    '33430': 'Belle Glade',
    '33431': 'Boca Raton',
    '33432': 'Downtown Boca Raton',
    '33433': 'Boca Rio BR',
    '33434': 'Boca Rio BR',
    '33435': 'Downtown Boynton Beach',
    '33436': 'Boynton Beach',
    '33437': 'Jog Road',
    '33438': 'Canal Point',
    '33440': 'Clewiston',
    '33441': 'Deerfield Beach',
    '33442': 'Deerfield Beach Annex',
    '33443': 'Deerfield Beach',
    '33444': 'Delray Beach',
    '33445': 'Delray Beach',
    '33446': 'West Delray Beach',
    '33448': 'West Delray Beach',
    '33449': 'Greenacres',
    '33454': 'Greenacres',
    '33455': 'Hobe Sound',
    '33458': 'Jupiter',
    '33459': 'Lake Harbor',
    '33460': 'Lucerne Avenue',
    '33461': 'Lake Worth',
    '33462': 'Lake Worth',
    '33463': 'Greenacres',
    '33465': 'Lantana',
    '33466': 'Lake Worth',
    '33467': 'Greenacres',
    '33468': 'Jupiter',
    '33469': 'Tequesta',
    '33470': 'Loxahatchee',
    '33471': 'Moore Haven',
    '33472': 'Jog Road',
    '33473': 'Jog Road',
    '33474': 'Jog Road',
    '33475': 'Hobe Sound',
    '33476': 'Pahokee',
    '33477': 'Tequesta',
    '33478': 'Jupiter',
    '33480': 'Palm Beach',
    '33481': 'Woodland',
    '33482': 'Delray Beach',
    '33483': 'CPU Pak It Tight',
    '33484': 'West Delray Beach',
    '33486': 'Palmetto Park Annex',
    '33487': 'Boca Raton',
    '33488': 'Boca Rio BR',
    '33493': 'Belle Glade',
    '33496': 'Boca Raton',
    '33497': 'West Boca Postal Store',
    '33498': 'West Boca Carrier Annex',
    '33503': 'Balm',
    '33508': 'Bloomingdale',
    '33509': 'Brandon',
    '33510': 'Brandon',
    '33511': 'Brandon',
    '33513': 'Bushnell',
    '33514': 'Center Hill',
    '33521': 'Coleman',
    '33523': 'Dade City',
    '33524': 'Crystal Springs',
    '33525': 'Dade City',
    '33526': 'Dade City',
    '33527': 'Dover',
    '33530': 'Durant',
    '33534': 'Gibsonton',
    '33537': 'Lacoochee',
    '33538': 'Lake Panasoffkee',
    '33539': 'Zephyrhills',
    '33540': 'Zephyrhills',
    '33541': 'Zephyrhills',
    '33542': 'Zephyrhills',
    '33543': 'Zephyrhills',
    '33544': 'Zephyrhills',
    '33545': 'Zephyrhills',
    '33547': 'Lithia',
    '33548': 'Lutz',
    '33549': 'Lutz',
    '33550': 'Mango',
    '33556': 'Odessa',
    '33558': 'Lutz',
    '33559': 'Lutz',
    '33563': 'Plant City',
    '33564': 'Plant City',
    '33565': 'Plant City',
    '33566': 'Plant City',
    '33567': 'Plant City',
    '33568': 'Riverview',
    '33569': 'Riverview',
    '33570': 'Ruskin Carrier Annex',
    '33571': 'Sun City Center',
    '33572': 'Apollo Beach',
    '33573': 'Sun City Center',
    '33574': 'Saint Leo',
    '33575': 'Ruskin',
    '33576': 'San Antonio',
    '33578': 'Riverview',
    '33579': 'Riverview',
    '33583': 'Seffner',
    '33584': 'Seffner',
    '33585': 'Sumterville',
    '33586': 'Sun City',
    '33587': 'Sydney',
    '33592': 'Thonotosassa',
    '33593': 'Trilby',
    '33594': 'Valrico',
    '33595': 'Valrico',
    '33596': 'Valrico',
    '33597': 'Webster',
    '33598': 'Wimauma',
    '33601': 'Commerce',
    '33602': 'Ybor City',
    '33603': 'Sulphur Springs',
    '33604': 'Sulphur Springs',
    '33605': 'Ybor City',
    '33606': 'Tampa Carrier Annex',
    '33607': 'Tampa Carrier Annex',
    '33608': 'MacDill AFB',
    '33609': 'Tampa Carrier Annex',
    '33610': 'Produce',
    '33611': 'Interbay',
    '33612': 'Forest Hills',
    '33613': 'Forest Hills',
    '33614': 'Hilldale',
    '33615': 'Town N Country',
    '33616': 'Interbay',
    '33617': 'Temple Terrace',
    '33618': 'Carrollwood',
    '33619': 'Palm River Carrier Annex',
    '33621': 'MacDill AFB',
    '33622': 'Tampa',
    '33623': 'Tampa',
    '33624': 'Northdale Carrier Annex',
    '33625': 'Ehrlich',
    '33626': 'Northdale Carrier Annex',
    '33629': 'Interbay',
    '33630': 'Tampa',
    '33631': 'Tampa',
    '33634': 'Hilldale',
    '33635': 'Town N Country',
    '33637': 'Temple Terrace',
    '33646': 'New Tampa',
    '33647': 'New Tampa',
    '33672': 'Commerce',
    '33673': 'Sulphur Springs',
    '33674': 'Sulphur Springs',
    '33675': 'Ybor City',
    '33677': 'West Tampa',
    '33679': 'Peninsula Tampa',
    '33680': 'Produce',
    '33681': 'Interbay',
    '33682': 'Forest Hills',
    '33684': 'Hilldale',
    '33685': 'Town N Country',
    '33687': 'Temple Terrace',
    '33688': 'Carrollwood',
    '33689': 'Falkenburg',
    '33694': 'Ehrlich',
    '33701': 'Open Air',
    '33702': 'Northside',
    '33703': 'Gateway Mall',
    '33704': 'Euclid',
    '33705': 'Midtown',
    '33706': 'Saint Pete Beach',
    '33707': 'Gulfwinds',
    '33708': 'Madeira Beach',
    '33709': 'Crossroads',
    '33710': 'Crossroads',
    '33711': 'Gulfwinds',
    '33712': 'Midtown',
    '33713': 'Saint Petersburg FL S&DC',
    '33714': 'Saint Petersburg FL S&DC',
    '33715': 'Gulfwinds',
    '33716': 'Northside',
    '33731': 'Open Air',
    '33732': 'Gateway Mall',
    '33733': 'Saint Petersburg FL S&DC',
    '33734': 'Euclid',
    '33736': 'Saint Pete Beach',
    '33738': 'Madeira Beach',
    '33741': 'Saint Pete Beach',
    '33742': 'Northside',
    '33743': 'Crossroads',
    '33744': 'Bay Pines',
    '33747': 'Gulfwinds',
    '33755': 'Cleveland Street',
    '33756': 'Clearwater',
    '33757': 'Cleveland Street',
    '33758': 'Clearwater',
    '33759': 'Clearwater',
    '33760': 'High Point',
    '33761': 'Sunset Point',
    '33762': 'High Point',
    '33763': 'Sunset Point',
    '33764': 'High Point',
    '33765': 'Sunset Point',
    '33766': 'Countryside',
    '33767': 'Cleveland Street',
    '33770': 'Largo',
    '33771': 'Largo',
    '33772': 'Seminole',
    '33773': 'Mission Plaza',
    '33774': 'Seminole',
    '33775': 'Seminole',
    '33776': 'Seminole',
    '33777': 'Seminole',
    '33778': 'Largo',
    '33779': 'Largo',
    '33780': 'Pinellas Park',
    '33781': 'Pinellas Park',
    '33782': 'Pinellas Park',
    '33784': 'Saint Petersburg FL S&DC',
    '33785': 'Indian Rocks Beach',
    '33786': 'Indian Rocks Beach',
    '33801': 'Downtown Lakeland',
    '33802': 'Downtown Lakeland',
    '33803': 'Downtown Lakeland',
    '33804': 'Lakeland FL S&DC',
    '33805': 'Lakeland FL S&DC',
    '33806': 'Dixieland',
    '33807': 'Southside Postal Store',
    '33809': 'Lakeland FL S&DC',
    '33810': 'Lakeland FL S&DC',
    '33811': 'Southside Postal Store',
    '33812': 'Southside Postal Store',
    '33813': 'Southside Postal Store',
    '33815': 'Downtown Lakeland',
    '33820': 'Alturas',
    '33823': 'Auburndale',
    '33825': 'Avon Park',
    '33826': 'Avon Park',
    '33827': 'Babson Park',
    '33830': 'Bartow',
    '33831': 'Bartow',
    '33834': 'Bowling Green',
    '33835': 'Bradley',
    '33836': 'Davenport',
    '33837': 'Davenport',
    '33838': 'Dundee',
    '33839': 'Eagle Lake',
    '33840': 'Eaton Park',
    '33841': 'Fort Meade',
    '33843': 'Frostproof',
    '33844': 'Haines City',
    '33845': 'Haines City',
    '33846': 'Highland City',
    '33847': 'Homeland',
    '33848': 'Intercession City',
    '33849': 'Kathleen',
    '33850': 'Lake Alfred',
    '33851': 'Lake Hamilton',
    '33852': 'Lake Placid',
    '33853': 'Lake Wales',
    '33854': 'Lakeshore',
    '33855': 'Indian Lake Estates',
    '33856': 'Nalcrest',
    '33857': 'Lorida',
    '33858': 'Loughman',
    '33859': 'Lake Wales',
    '33860': 'Mulberry',
    '33862': 'Lake Placid',
    '33863': 'Nichols',
    '33865': 'Ona',
    '33867': 'Lake Wales',
    '33868': 'Polk City',
    '33870': 'Sebring',
    '33871': 'Sebring',
    '33872': 'Lake Jackson Branch',
    '33873': 'Wauchula',
    '33875': 'Lake Jackson Branch',
    '33876': 'Sebring',
    '33877': 'Waverly',
    '33880': 'Winter Haven',
    '33881': 'Florence Villa',
    '33882': 'Winter Haven',
    '33883': 'Winter Haven',
    '33884': 'Florence Villa',
    '33885': 'Florence Villa',
    '33890': 'Zolfo Springs',
    '33896': 'Davenport',
    '33897': 'Davenport',
    '33898': 'Lake Wales',
    '33901': 'Downtown Fort Myers',
    '33902': 'Downtown Fort Myers',
    '33903': 'North Fort Myers',
    '33904': 'Cape Coral Central',
    '33905': 'Tice',
    '33906': 'Page Field',
    '33907': 'Page Field',
    '33908': 'Six Mile Cypress Annex',
    '33909': 'Cape Coral Central',
    '33910': 'Cape Coral South',
    '33912': 'Six Mile Cypress Annex',
    '33913': 'Page Field',
    '33914': 'Cape Coral Central',
    '33915': 'Cape Coral Central',
    '33916': 'Page Field',
    '33917': 'North Fort Myers',
    '33918': 'North Fort Myers',
    '33919': 'CPU Atrium',
    '33920': 'Alva',
    '33921': 'Boca Grande',
    '33922': 'Bokeelia',
    '33924': 'Captiva',
    '33927': 'El Jobean',
    '33928': 'Estero',
    '33929': 'Estero',
    '33930': 'Felda',
    '33931': 'Fort Myers Beach',
    '33932': 'Fort Myers Beach',
    '33935': 'Labelle',
    '33936': 'Lehigh Acres',
    '33938': 'Murdock',
    '33944': 'Palmdale',
    '33945': 'Pineland',
    '33946': 'Placida',
    '33947': 'Placida',
    '33948': 'Port Charlotte Annex',
    '33949': 'Port Charlotte',
    '33950': 'Punta Gorda',
    '33951': 'Punta Gorda',
    '33952': 'Port Charlotte Annex',
    '33953': 'Port Charlotte Annex',
    '33954': 'Port Charlotte Annex',
    '33955': 'Punta Gorda',
    '33956': 'Saint James City',
    '33957': 'Sanibel',
    '33960': 'Venus',
    '33966': 'Page Field',
    '33967': 'Six Mile Cypress Annex',
    '33970': 'Lehigh Acres',
    '33971': 'Lehigh Acres',
    '33972': 'Lehigh Acres',
    '33973': 'Lehigh Acres',
    '33974': 'Lehigh Acres',
    '33975': 'Labelle',
    '33976': 'Lehigh Acres',
    '33980': 'Punta Gorda',
    '33981': 'Port Charlotte Annex',
    '33982': 'Punta Gorda',
    '33983': 'Punta Gorda',
    '33990': 'Cape Coral Central',
    '33991': 'Cape Coral North',
    '33993': 'Cape Coral North',
    '34101': 'Naples',
    '34102': 'Naples',
    '34103': 'Naples',
    '34104': 'East Naples Carrier Annex',
    '34105': 'Naples',
    '34106': 'Downtown Naples',
    '34107': 'Coco River',
    '34108': 'Coco River',
    '34109': 'Coco River',
    '34110': 'Coco River',
    '34112': 'East Naples Carrier Annex',
    '34113': 'Naples',
    '34114': 'Naples',
    '34116': 'Golden Gate',
    '34117': 'Golden Gate Carrier Annex',
    '34119': 'Golden Gate Carrier Annex',
    '34120': 'Golden Gate Carrier Annex',
    '34133': 'Bonita Beach Road',
    '34134': 'Bonita Springs',
    '34135': 'Bonita Springs',
    '34136': 'Bonita Springs',
    '34137': 'Everglades City',
    '34138': 'Everglades City',
    '34139': 'Everglades City',
    '34140': 'Goodland',
    '34141': 'Ochopee',
    '34142': 'Immokalee',
    '34143': 'Immokalee',
    '34145': 'Marco Island',
    '34146': 'Marco Island',
    '34201': 'Bradenton',
    '34202': 'Lakewood Ranch',
    '34203': 'Bradenton',
    '34204': 'Bradenton',
    '34205': 'Bradenton',
    '34206': 'Bradenton',
    '34207': 'Bradenton',
    '34208': 'Bradenton',
    '34209': 'Bradenton',
    '34210': 'Bradenton',
    '34211': 'Lakewood Ranch',
    '34212': 'Bradenton',
    '34215': 'Cortez',
    '34216': 'Anna Maria',
    '34217': 'Bradenton Beach',
    '34218': 'Bradenton',
    '34219': 'Parrish',
    '34220': 'Palmetto',
    '34221': 'Palmetto',
    '34222': 'Ellenton',
    '34223': 'Englewood',
    '34224': 'Englewood',
    '34228': 'Longboat Key',
    '34229': 'Osprey',
    '34230': 'Sarasota FL S&DC',
    '34231': 'Gulf Gate',
    '34232': 'Glengarry',
    '34233': 'Glengarry',
    '34234': 'Sarasota FL S&DC',
    '34235': 'Glengarry',
    '34236': 'Sarasota FL S&DC',
    '34237': 'Sarasota FL S&DC',
    '34238': 'Sarasota Carrier Annex',
    '34239': 'Southgate',
    '34240': 'Glengarry',
    '34241': 'Sarasota Carrier Annex',
    '34242': 'Siesta Key',
    '34243': 'Sarasota',
    '34250': 'Terra Ceia',
    '34251': 'Myakka City',
    '34264': 'Oneco',
    '34265': 'Arcadia',
    '34266': 'Arcadia',
    '34267': 'Fort Ogden',
    '34268': 'Nocatee',
    '34269': 'Arcadia',
    '34270': 'Tallevast',
    '34272': 'Laurel',
    '34274': 'Nokomis',
    '34275': 'Nokomis',
    '34276': 'Gulf Gate',
    '34277': 'Southgate',
    '34280': 'Palma Sola',
    '34281': 'Cottage Trailer',
    '34282': 'Fifty Seventh Avenue',
    '34284': 'Venice',
    '34285': 'Venice',
    '34286': 'North Port',
    '34287': 'North Port',
    '34288': 'North Port',
    '34289': 'North Port',
    '34290': 'City Center',
    '34291': 'North Port',
    '34292': 'Venice',
    '34293': 'Venice Carrier Annex',
    '34295': 'Englewood',
    '34420': 'Belleview',
    '34421': 'Belleview',
    '34423': 'Crystal River',
    '34428': 'Crystal River',
    '34429': 'Crystal River',
    '34430': 'Dunnellon',
    '34431': 'Dunnellon',
    '34432': 'Dunnellon',
    '34433': 'Dunnellon',
    '34434': 'Dunnellon',
    '34436': 'Floral City',
    '34441': 'Hernando',
    '34442': 'Lecanto',
    '34445': 'Holder',
    '34446': 'Homosassa Springs',
    '34447': 'Homosassa Springs',
    '34448': 'Homosassa Springs',
    '34449': 'Inglis',
    '34450': 'Inverness',
    '34451': 'Inverness',
    '34452': 'Inverness',
    '34453': 'Inverness',
    '34460': 'Lecanto',
    '34461': 'Lecanto',
    '34464': 'Beverly Hills',
    '34465': 'Lecanto',
    '34470': 'Ocala',
    '34471': 'Ocala',
    '34472': 'Maricamp',
    '34473': 'Paddock',
    '34474': 'Paddock',
    '34475': 'Ocala',
    '34476': 'Paddock',
    '34477': 'Paddock',
    '34478': 'Ocala',
    '34479': 'Ocala',
    '34480': 'Maricamp',
    '34481': 'Paddock',
    '34482': 'Paddock',
    '34483': 'Maricamp',
    '34484': 'Oxford',
    '34491': 'Summerfield',
    '34492': 'Summerfield',
    '34498': 'Inglis',
    '34601': 'Brooksville',
    '34602': 'Brooksville',
    '34603': 'Brooksville',
    '34604': 'Aviation',
    '34605': 'Brooksville',
    '34606': 'Spring Hill',
    '34607': 'Spring Hill',
    '34608': 'Spring Hill',
    '34609': 'Aviation',
    '34610': 'Shady Hills',
    '34611': 'Plaza',
    '34613': 'Brooksville',
    '34614': 'Brooksville',
    '34636': 'Istachatta',
    '34637': 'Land O Lakes',
    '34638': 'Land O Lakes',
    '34639': 'Land O Lakes',
    '34652': 'New Port Richey',
    '34653': 'New Port Richey',
    '34654': 'Trinity Carrier Annex',
    '34655': 'Trinity Carrier Annex',
    '34656': 'New Port Richey',
    '34660': 'Ozona',
    '34661': 'Nobleton',
    '34667': 'Hudson',
    '34668': 'Port Richey',
    '34669': 'Hudson',
    '34673': 'Embassy Crossing',
    '34674': 'Hudson',
    '34677': 'Oldsmar',
    '34679': 'Aripeka',
    '34680': 'Elfers',
    '34681': 'Crystal Beach',
    '34682': 'Palm Harbor',
    '34683': 'Palm Harbor',
    '34684': 'Palm Harbor',
    '34685': 'Palm Harbor',
    '34688': 'Tarpon Springs',
    '34689': 'Tarpon Springs',
    '34690': 'Tarpon Springs',
    '34691': 'Tarpon Springs',
    '34692': 'Holiday',
    '34695': 'Safety Harbor',
    '34697': 'Dunedin',
    '34698': 'Dunedin',
    '34705': 'Astatula',
    '34711': 'Clermont Carrier Annex',
    '34712': 'Clermont',
    '34713': 'South Clermont',
    '34714': 'Clermont Carrier Annex',
    '34715': 'Clermont Carrier Annex',
    '34729': 'Ferndale',
    '34731': 'Fruitland Park',
    '34734': 'Gotha',
    '34736': 'Groveland',
    '34737': 'Howey In The Hills',
    '34739': 'Kenansville',
    '34740': 'Oakland',
    '34741': 'Oak Street',
    '34742': 'Oak Street',
    '34743': 'Kissimmee',
    '34744': 'Kissimmee',
    '34745': 'Kissimmee',
    '34746': 'Kissimmee Carrier Annex',
    '34747': 'Celebration',
    '34748': 'Leesburg',
    '34749': 'Leesburg',
    '34753': 'Mascotte',
    '34755': 'Minneola',
    '34756': 'Montverde',
    '34758': 'Poinciana',
    '34759': 'Poinciana',
    '34760': 'Oakland',
    '34761': 'Ocoee',
    '34762': 'Leesburg',
    '34769': 'Saint Cloud',
    '34770': 'Saint Cloud',
    '34771': 'Saint Cloud',
    '34772': 'Saint Cloud',
    '34773': 'Saint Cloud',
    '34777': 'Winter Garden',
    '34778': 'Winter Garden',
    '34785': 'Wildwood',
    '34786': 'Windermere',
    '34787': 'Winter Garden',
    '34788': 'Leesburg',
    '34789': 'Haines Creek',
    '34797': 'Yalaha',
    '34945': 'Fort Pierce',
    '34946': 'Orange Avenue',
    '34947': 'Orange Avenue',
    '34948': 'Orange Avenue',
    '34949': 'Orange Avenue',
    '34950': 'Orange Avenue',
    '34951': 'Orange Avenue',
    '34952': 'Port Saint Lucie',
    '34953': 'Port Saint Lucie',
    '34954': 'Orange Avenue',
    '34956': 'Indiantown',
    '34957': 'Jensen Beach',
    '34958': 'Jensen Beach',
    '34972': 'Okeechobee',
    '34973': 'Okeechobee',
    '34974': 'Okeechobee',
    '34979': 'Fort Pierce',
    '34981': 'Fort Pierce',
    '34982': 'Fort Pierce',
    '34983': 'Fort Pierce',
    '34984': 'Port Saint Lucie',
    '34985': 'Port Saint Lucie',
    '34986': 'Port Saint Lucie',
    '34987': 'Port Saint Lucie',
    '34988': 'Port Saint Lucie',
    '34990': 'Palm City',
    '34991': 'Palm City',
    '34992': 'Port Salerno',
    '34994': 'Stuart',
    '34995': 'Stuart',
    '34996': 'Stuart',
    '34997': 'Stuart'  
  };
  
  

const ZipCodeChecker = () => {
  const [zipCode, setZipCode] = useState('');
  const [result, setResult] = useState('');

  const handleCheckZipCode = () => {
    const area = zipCodeData[zipCode];
    if (area) {
      setResult(`Congratulations! We service your area: ${area}.`);
    } else {
      setResult(`Sorry, we do not service this area. Please check another zip code.`);
    }
  };

  return (
    <div 
      className="relative min-h-screen bg-cover bg-center flex items-center justify-center" 
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black/60"></div> {/* Overlay for darkening the background */}
      
      <div className="relative bg-white shadow-md rounded-lg p-4 sm:p-6 w-full max-w-md mx-auto z-10">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center text-gray-800">
          Check If We Service Your Area
        </h2>
        <div className="relative w-full mb-4">
          <input
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            placeholder="Enter zip code"
            className="w-full border border-gray-300 rounded-full px-3 sm:px-4 py-2 sm:py-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
          />
          <Search className="absolute right-3 top-2 text-gray-500" size={24} />
        </div>
        <button
          onClick={handleCheckZipCode}
          className="bg-blue-600 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-full w-full hover:bg-blue-700 transition duration-300 flex items-center justify-center"
        >
          <MapPin className="mr-2" size={20} />
          Check Zip Code
        </button>
        {result && (
          <p className={`mt-4 text-lg text-center ${result.includes('Congratulations') ? 'text-green-600' : 'text-red-600'}`}>
            {result}
          </p>
        )}
      </div>
    </div>
  );
};

export default ZipCodeChecker;
