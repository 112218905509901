import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import ContactPage from './components/ContactPage'; // Add the ContactPage component
import './App.css'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/contact" element={<ContactPage />} />  {/* Add the Contact route */}
      </Routes>
    </Router>
  );
};

export default App;
