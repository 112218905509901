import React, { useState, useEffect } from 'react';
import Header from './Header';
import Home from './Home';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import Industries from './Industries';
import Cooper from '../Images/Cooperwhite.png';
import ZipCodeChecker from './ZipCodeChecker';

const MainLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsOpen(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative min-h-screen">
      <style jsx global>{`
        html {
          scroll-behavior: smooth;
        }
        .garage-door {
          transition: transform 2s ease-in-out;
          z-index: 9999; /* Ensure it's above everything */
        }
        .garage-door-panel {
          height: 25%;
          border-bottom: 2px solid #1d4ed8;
          position: relative;
        }
        .garage-door-panel::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 10px;
          right: 10px;
          height: 2px;
          background-color: #1d4ed8;
        }
        .garage-door-hinge {
          width: 20px;
          height: 30px;
          background-color: #4b5563;
          position: absolute;
          top: -15px;
          border-radius: 5px;
        }
        .garage-door-hinge-left {
          left: 40px;
        }
        .garage-door-hinge-right {
          right: 40px;
        }
      `}</style>

      {/* Enhanced Garage Door Animation with Logo */}
      <div
        className={`fixed inset-0 bg-blue-600 garage-door ${
          isOpen ? 'transform -translate-y-full' : ''
        }`}
      >
        {[...Array(4)].map((_, index) => (
          <div key={index} className="garage-door-panel">
            <div className="garage-door-hinge garage-door-hinge-left"></div>
            <div className="garage-door-hinge garage-door-hinge-right"></div>
          </div>
        ))}
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src={Cooper}
            alt="Cooper Garage Door Repair Logo"
            className="w-1/2 max-w-md object-contain"
          />
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-20 h-6 bg-gray-800 rounded-t-lg"></div>
      </div>

      {/* Wrapper for content below the garage door */}
      <div className="relative z-10">
        <Header scrollToSection={scrollToSection} />

        <main>
          <Home scrollToSection={scrollToSection} />
          <Services />
          <ZipCodeChecker/>
          <Industries />
          <About />
          <Contact />
        </main>

        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;