import React from 'react';
import { motion } from 'framer-motion';
import Background1 from '../Images/background1.jpg';


const Home = ({ scrollToSection }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    }
  };

  const handleCallClick = () => {
    window.location.href = 'tel:3053004211';
  };

  return (
    <section id="home" className="relative min-h-screen flex items-center justify-center overflow-hidden">
      {/* Background Image */}
      <motion.div 
        className="absolute inset-0 z-0"
        initial={{ scale: 1.1 }}
        animate={{ scale: 1 }}
        transition={{ duration: 10, ease: "easeOut" }}
      >
        <img
          src={Background1}
          alt="Garage door background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/30"></div>
      </motion.div>
      
      {/* Content */}
      <motion.div 
        className="relative z-10 text-white px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto w-full pt-20 md:pt-0"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <motion.div variants={itemVariants} className="lg:w-1/2 mb-8 lg:mb-0">
            <motion.h1 
              className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 leading-tight"
              variants={itemVariants}
            >
              Cooper Garage<br />Door Repair
            </motion.h1>
            <motion.p 
              className="text-xl sm:text-2xl lg:text-3xl font-semibold mb-6 italic text-blue-300"
              variants={itemVariants}
            >
              "We fix what your husband tried to"
            </motion.p>
            <motion.p 
              className="text-lg mb-8 max-w-xl"
              variants={itemVariants}
            >
              Servicing South Florida with top-quality garage door installation, repair, and maintenance services.
              Our experienced technicians ensure your garage door operates smoothly and safely.
            </motion.p>
            <motion.div 
              className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"
              variants={itemVariants}
            >
              <motion.button 
                className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition duration-300 text-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleCallClick}
              >
                Get a Free Estimate
              </motion.button>
              <motion.button 
                className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-full hover:bg-white hover:text-blue-600 transition duration-300 text-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => scrollToSection('services')}
              >
                Our Services
              </motion.button>
            </motion.div>
          </motion.div>
          <motion.div 
            variants={itemVariants}
            className="lg:w-1/2 mt-8 lg:mt-0"
          >
            <motion.div 
              className="bg-white/10 backdrop-blur-md rounded-lg p-6 shadow-xl"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <h2 className="text-2xl font-bold mb-4">Why Choose Us?</h2>
              <ul className="space-y-2">
                {["24/7 Emergency Service", "Experienced Technicians", "Affordable Pricing", "Satisfaction Guaranteed"].map((item, index) => (
                  <motion.li 
                    key={index}
                    className="flex items-center"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3, delay: 0.3 + index * 0.1 }}
                  >
                    <svg className="w-5 h-5 mr-2 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                    {item}
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default Home;